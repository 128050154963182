import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Subscription} from "rxjs";
import {TenantService} from "./tenant.service";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements  OnDestroy{

  authenticatedOptions: any;
  tokenSubscription: Subscription;

  constructor(public http: HttpClient, private auth: AuthService) {
    this.tokenSubscription = this.auth.currentToken$.subscribe((data: TokenStore | null) => {

      console.log(data);

      if(data){
        this.authenticatedOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.access_token
          }),
        };
      }
    });
  }

  checkPublicSubscriptionStatus(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/client/settings/status',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  refreshTokenBalance(){

    this.getPackageAndBalance().then((res)=>{
      this.auth.currentTokenBalance.next(res.Remaining);
    })

  }

  getPackageAndBalance(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/client/settings/balance',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  checkOwnerSubscriptionStatus(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/tenant-api/core/settings/payment/subscription',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  createSetupIntent(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/core/settings/payment/setupIntent', {},  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  updatePaymentMethod(methodID: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/core/settings/payment/saveMethod', {
          paymentMethod: methodID
        },  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  updatePaymentMethodSubscribe(methodID: string, planID: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/core/settings/payment/saveMethodAndSubscribe', {
          paymentMethod: methodID,
          plan: planID
        },  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  endTrialEarly(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/tenant-api/core/settings/payment/endTrial', {},  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }

}
