import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Observable} from "rxjs";
import {DateTime} from "luxon";
import {SubscriptionService} from "../services/subscription.service";

@Injectable({providedIn: 'root'})
export class SubscribedGuard implements CanActivate {
  constructor(private subscriptionService: SubscriptionService, private auth:AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      console.log('Subscribe Guard Started');

      let redirect = '/setup/unavailable';

      const subscription = this.auth.currentPaymentStatusSubject.value;

      if(!subscription){

        // NO SUBSCRIPTION SUBJECT SET

        // CHECK WITH THE SERVER ABOUT THE SUBSCRIPTION STATUS
        this.subscriptionService.checkPublicSubscriptionStatus().then((SubscriptionStatus) =>{
          // THERE IS AN EXISTING SUBSCRIPTION
          this.auth.currentPaymentStatusSubject.next(SubscriptionStatus);

          if(this.canAccess()){

            // THEY ARE ALLOWED TO ACCESS THE SOFTWARE, NOW LETS GET THEIR TOKEN BALANCE

            resolve(true);

          }else{
            // SUBSCRIPTION IS INVALID
            this.router.navigateByUrl(redirect)
            resolve(false);
          }

        }).catch((SubscriptionError)=>{

          console.log(SubscriptionError);
          // TODO ADD ADDITIONAL LOGIC HERE TO HANDLE GENERIC ERRORS AS CURRENTLY A FAIL WILL MAKE IT LOOK
          //  LIKE THE USER ISN'T SUBSCRIBED

          this.auth.currentPaymentStatusSubject.next(null);
          this.router.navigateByUrl(redirect)
          resolve(false);
        });

      }else{

        // SUBSCRIPTION HAS ALREADY BEEN CHECKED AND STORED IN SUBJECT, DON'T REPEAT IT TO REDUCE MEMORY / CPU LOAD
        resolve(true);

      }

    });
  }

  canAccess(): boolean {

    const subscription = this.auth.currentPaymentStatusSubject.value;

    if(!subscription){
      return false;
    }else{

      return subscription.StatusFlags.OnTrial ||
        subscription.StatusFlags.DueToEnd ||
        subscription.StatusFlags.Active;

    }

  }

}
