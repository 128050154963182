export const environment = {
  production: false,
  appVersion: 'v1',
  host_domain: 'https://api-uat.socialyze.co',
  tenant_domain: 'api-uat.socialyze.co',
  media_domain: 'media.staging.socialyze.co',
  postcodeLookup: 'api.postcodes.io/postcodes/',
  httpProtocol: 'https://',
  recaptcha: {
    siteKey: '6LepOIcdAAAAAHX0OLcR-k6dAd26uD7PG5CQN1l_',
  },
  googleMapsKey: 'AIzaSyD33nWk9C_PSlHe1EZkEI_M1Mi-CGCgBDw',
  stripe_public_key: 'pk_test_51KX4BnB4rh0J9B4tVxqfqLO57636psGOlt2ycjT5TqoQVDedFy0OWoDeRx8yiELMVQ4uztljfAWZ79flxX5z3YfE00Ds4pZBrR',
  clientIDs: {
    core: 1,
    influencer: 2,
    client: 3
  },
  currencyCodes: [
    {
      code: 'gbp',
      symbol: '£ (GBP)'
    },
    {
      code: 'aud',
      symbol: '$ (AUD)'
    },
    {
      code: 'usd',
      symbol: '$ (USD)'
    }
  ],
  plans:{
    enterprise: "price_1Kf1XoB4rh0J9B4tHrqj7d5R",
    premium: "price_1Kf1XGB4rh0J9B4tZNSUPvSf",
    standard: "price_1Kf1W9B4rh0J9B4tlZQ8q4eZ"
  }
};

